import {useCallback, useEffect, useState} from 'react';
import styles from './RegistrationLanding.module.scss';
import store from 'redux/store';
import uuid from 'react-uuid';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {saveErrorDetails, updateProfileFkoApi, verifyRecaptcha} from 'api/api';
import {executeRecaptchaV3} from 'utils/utils';
import {
  createUserInBackground,
  FkoUserData,
  setFkoInitialParams,
  setFkoPlayersArray,
  setFkoSiteId,
  setFkoUserData,
} from '../../redux/slices/fkoFormData';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {useLoadScript} from '../../common/use-load-script.hook';
import {isLocationPermitted} from 'feature-flags/fko-locations';
import {logAnalyticsEvent} from 'common/analytics-events';
import {parseFkoParamsFromSearchParams, recaptchaSiteKey, sendFkoHubspotData} from 'common/fko-utils';
import {FkoRegistrationLandingForm, RegistrationFormTypes} from 'pages/RegistrationLanding/RegistrationLandingForm';
import {FKOFormContainer} from 'components/FKO/FKOFormContainer/FKOFormContainer';
import {FormHeading} from 'components/Forms/FormHeading/FormHeading';
import {TocaLogo} from 'components/Forms/FkoLogos/TocaLogo';
import {FKOModal} from 'components/FKO/FKOModal/FKOModal';
import {ErrorModal} from 'components/FKO/PlayersListModals/FkoPlayersListModals';
import {useQueryClient} from '@tanstack/react-query';
import {setCurrentAccountHolderProfileId, setCurrentAccountId} from 'user/user-utils';
import {useGetSport} from 'common/useGetSport';
import {IS_TEST_ENVIRONMENT} from 'common/isTestEnvironment';
import {getLocationBySiteId} from '../../constants/locations';
import fcDallasLogo from '../../assets/img/fc-dallas-logo.png';
import {ReactComponent as MyTocaLogoWhiteSvg} from '../../assets/img/my-toca-logo-white.svg';
import {getFkoInitialParamsLocalstorage, setFkoInitialParamsLocalstorage} from 'common/fko-localstorage';
import {useGetCachedUserExists} from 'api/FKO/useGetCachedUserExists';
import {DbatLogo} from 'components/Forms/FkoLogos/DbatLogo';
import {useGetTeamPublicInfo} from 'api/User/useGetTeamPublicInfo';
import {LoadingAnimation} from 'components/Loader/LoadingAnimation';

export const RegistrationLanding = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {sessionId} = useParams();
  const {teamId, locationId} = useAppSelector((state) => state.fkoFormDataReducer.initialParams);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {getCachedUserExists} = useGetCachedUserExists();
  const {data: teamPublicInfo, isLoading: isLoadingTeamInfo} = useGetTeamPublicInfo({teamId});
  const {
    isDbat,
    isPlayerAssessment,
    isClasses,
    isFreeTrialClasses,
    basePath,
    basePathWithoutSessionId,
    sport,
    fkoMethod,
    fkoAnalyticsPrefix,
  } = useGetSport();

  useLoadScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`);

  useEffect(() => {
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_registration_landing_view`);
    const urlParams = parseFkoParamsFromSearchParams(new URL(document.location.href).searchParams);

    // if we have a valid sessionId, we reload the correct initial params (this is probably a page refresh)
    if (sessionId) {
      const _fkoInitialParams = getFkoInitialParamsLocalstorage({paramsId: sessionId, sport}) ?? {...urlParams, sport};
      dispatch(setFkoInitialParams(_fkoInitialParams));
    } else {
      const fkoInitialParams = {...urlParams, sport};
      const {paramsId} = setFkoInitialParamsLocalstorage(fkoInitialParams);
      dispatch(setFkoInitialParams(fkoInitialParams));
      navigate(`/${paramsId}${basePathWithoutSessionId}`, {replace: true});
    }

    if (urlParams.embed === 'true') {
      document.body.classList.add('embedded');
    }

    // ---don't include `sessionId` in dependencies---
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePathWithoutSessionId, dispatch, fkoAnalyticsPrefix, navigate, sport]);

  const checkUserExistsAndFkoEligibility = useCallback(
    async ({email, siteId, formData}: {email: string; siteId: string; formData?: RegistrationFormTypes}) => {
      getCachedUserExists({
        email,
        siteId,
        sessionTypeNames: store.getState().fkoFormDataReducer.initialParams.sessionTypeNames,
      })
        .then(async (res) => {
          const userId = res.data.userId;
          const profiles = res.data.profiles ?? [];

          if (userId) {
            if (!profiles.length) {
              // this should essentially never happen
              console.warn('User account exists but no profiles found. Trying to create a new profile.');
              const newProfile = await updateProfileFkoApi({
                profileId: '', // blank for initial profile
                siteId: siteId,
                update: {
                  email,
                  firstName: (formData?.firstName ?? '').trim(),
                  lastName: (formData?.lastName ?? '').trim(),
                  phoneNumber: formData?.phoneNumber,
                  dob: formData?.dob,
                },
              });
              profiles.push(newProfile);
            }

            const accountHolder =
              profiles.find((profile) => profile.accountHolder) ??
              profiles.find(
                (profile) =>
                  profile.firstName.toLowerCase() === formData?.firstName.toLowerCase() &&
                  profile.lastName.toLowerCase() === formData?.lastName.toLowerCase()
              ) ??
              profiles.find((profile) => profile.accountId) ??
              profiles[0];

            if (!accountHolder) {
              throw new Error('588 Error: no accountHolder found');
            }

            const _userData: FkoUserData = {
              accountId: userId,
              dob: accountHolder?.dob ?? formData?.dob,
              email,
              firstName: accountHolder?.firstName ?? formData?.firstName ?? '',
              lastName: accountHolder?.lastName ?? formData?.lastName ?? '',
              location: accountHolder?.mboDetails?.siteId ?? siteId ?? '',
              phoneNumber:
                accountHolder?.phoneNumber && accountHolder?.phoneNumber !== '0000000000'
                  ? accountHolder?.phoneNumber
                  : formData?.phoneNumber,
            };

            setCurrentAccountId(userId);
            setCurrentAccountHolderProfileId(accountHolder._id);

            dispatch(setFkoUserData(_userData));
            dispatch(setFkoSiteId(siteId));
            dispatch(
              setFkoPlayersArray(
                profiles.map((profile) => ({
                  ...profile,
                  playerId: profile.mboDetails?.clientId ?? uuid(),
                }))
              )
            );
          } else if (formData && queryClient) {
            dispatch(createUserInBackground({siteId, email, formData, queryClient}));
            // because of data imports, it is possible to have a profile without an account
            if (profiles.length) {
              dispatch(
                setFkoPlayersArray(
                  profiles.map((profile) => ({
                    ...profile,
                    playerId: profile.mboDetails?.clientId ?? uuid(),
                  }))
                )
              );
            }
          } else {
            throw new Error('599 Error: no userId or formData');
          }
        })
        .catch((err) => {
          saveErrorDetails({
            key: 'fko-registration-landing-checkUserExistsAndFkoEligibility',
            severity: 'high',
            message: 'Caught error in RegistrationLanding.checkUserExistsAndFkoEligibility',
            description: err.message,
            context: 'fko',
            email,
            siteId,
            url: window.location.href,
            data: {
              formData,
              error: err,
            },
          });
          setErrorMessage(`${err.includes('599') ? '599' : '588'} Error checking user email`);
        });
    },
    [basePath, dispatch, getCachedUserExists, navigate, queryClient]
  );

  const onSubmitHandler = useCallback(
    async (data: RegistrationFormTypes) => {
      setIsLoading(true);
      if (!IS_TEST_ENVIRONMENT && process.env.REACT_APP_IS_CAPTCHA_DISABLED !== 'true') {
        try {
          const recaptchaToken = await executeRecaptchaV3(recaptchaSiteKey);
          const recaptchaResult = await verifyRecaptcha(recaptchaToken);
          if (!recaptchaResult.success || recaptchaResult.score < 0.3) {
            setErrorMessage('reCAPTCHA challenge failed');
            setIsLoading(false);
            return;
          }
        } catch (err) {
          setErrorMessage('reCAPTCHA challenge failed');
          setIsLoading(false);
          return;
        }
      }

      dispatch(setFkoUserData(data));
      dispatch(setFkoSiteId(data.location));
      sendFkoHubspotData({
        alternateProfileData: data,
        initialParams: store.getState().fkoFormDataReducer.initialParams,
        locationId: data.location,
        fkoMethod,
      });

      await checkUserExistsAndFkoEligibility({email: data.email, siteId: data.location, formData: data}).finally(() => {
        setIsLoading(false);
        navigate(`${basePath}/players`);
      });
    },
    [fkoMethod, basePath]
  );

  let locationIsBlocked = false;
  if (locationId) {
    const location = getLocationBySiteId(locationId);
    if (!location) {
      locationIsBlocked = true;
    } else {
      locationIsBlocked = !isLocationPermitted({locationId, isDbat, isPlayerAssessment, isClasses, isFreeTrialClasses});
    }
  }

  if (locationIsBlocked) {
    return (
      <div className={styles.restrictionOverlay}>
        <div className={styles.restrictionText}>
          {isDbat ? <DbatLogo withBackground={true} /> : <TocaLogo />}
          <h1>Your location is not yet available</h1>
          <h2>Please check back soon!</h2>
          <p>
            <Link to={`${basePathWithoutSessionId}/restart`} className="link-btn">
              Sign out
            </Link>
          </p>
        </div>
      </div>
    );
  }

  let pageTitle = 'MyTOCA | Free Baseline Session';
  let formHeading = (
    <FormHeading heading="GET STARTED NOW" subheading="Schedule a FREE, no-commitment private skills assessment" />
  );

  if (isPlayerAssessment) {
    const isPlayerAssessmentLoading = !!teamId && isLoadingTeamInfo;
    // ---TEMP ensure we don't break existing FCD functionality---
    const isFCDallas = locationId === '5733380';
    const logoUrl = isFCDallas ? fcDallasLogo : teamPublicInfo?.logoUrl; // TODO find a better placeholder?
    const teamName = isFCDallas ? 'FC Dallas' : teamPublicInfo?.teamName ?? 'Player Assessment';
    const locationDetail = isFCDallas ? 'UMB Bank Performance Center' : ''; // TODO get param from somewhere?

    pageTitle = 'MyTOCA | Player Assessment';
    formHeading = (
      <div className="player-assessment-heading-section">
        <div className="flex justify-center h-[14px] mt-4">
          <MyTocaLogoWhiteSvg />
        </div>
        {isPlayerAssessmentLoading ? (
          <LoadingAnimation darkMode />
        ) : (
          <>
            {logoUrl ? (
              // only fade-in if the logo was loaded in (`teamId` enables the `teamPublicInfo` hook)
              <div className={`flex justify-center h-[100px] mt-8 ${teamId ? 'animate-fade-in' : ''}`}>
                <img
                  src={logoUrl}
                  alt={`${teamName} Logo`}
                  className="h-[100px] w-[100px] max-w-[100px] object-cover border-2 border-[#E7E7E7] rounded-md"
                />
              </div>
            ) : (
              <div className={teamId ? `h-[100px]` : `h-[40px]`} /> // prevents layout shift between loading states
            )}
            <h1 className="font-helvetica text-white text-center text-[26px] font-bold">{teamName}</h1>
            <h2 className="font-poppins text-white text-center text-base mt-4 mb-3 px-6">
              {`Please sign up and register for your player assessment` +
                (locationDetail ? ` at the ${locationDetail}` : ``)}
            </h2>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <body className="fko-registration-page" />
      </Helmet>

      <FKOModal isOpen={!!errorMessage} closeModal={() => setErrorMessage(undefined)} heading="Something went wrong...">
        <ErrorModal errorText={errorMessage} showRawErrorText={false} />
      </FKOModal>

      <FKOFormContainer restartLink={false}>
        {formHeading}
        <FkoRegistrationLandingForm
          errorMessage={errorMessage}
          onSubmitHandler={onSubmitHandler}
          getCachedUserExists={getCachedUserExists}
          isLoading={isLoading}
        />
      </FKOFormContainer>
    </>
  );
};
