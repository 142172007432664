import {memo, useState, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {PageHeading} from 'components/Connects/PageHeading';
import {
  CommonRegistrationForm,
  CommonRegistrationFormTypes,
  FormFieldConfig,
} from 'components/Forms/CommonRegistrationForm/CommonRegistrationForm';
import * as yup from 'yup';
import {PHONE_REGEX} from 'utils/utils';
import {submitHubSpotForm} from 'api/api';
import {TrackingStrings} from 'redux/slices/fkoFormData';
import {parseFTCDSRParamsFromSearchParams, recaptchaSiteKey} from 'common/fko-utils';
import Spinner from 'components/Loader/Spinner';
import {useLoadScript} from 'common/use-load-script.hook';
import {executeRecaptchaV3} from 'utils/utils';
import {verifyRecaptcha} from 'api/api';
import {IS_TEST_ENVIRONMENT} from 'common/isTestEnvironment';

type UrlParams = {
  [key in TrackingStrings]?: string;
} & {
  locations__drop_down_?: string;
};

const FtcDsrClassForm = memo(() => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const locationsAllowed = ['Denver', 'Loganville', 'Farmington', 'Cincinnati', 'Toledo'];

  const urlParams: UrlParams = parseFTCDSRParamsFromSearchParams(new URL(document.location.href).searchParams);

  const validLocation = urlParams.locations__drop_down_
    ? locationsAllowed.find((location) => location.toLowerCase() === urlParams.locations__drop_down_?.toLowerCase())
    : undefined;

  const formFields: FormFieldConfig[] = useMemo(() => {
    const fields: FormFieldConfig[] = [
      {
        name: 'firstName',
        type: 'text',
        placeholder: 'Parent/Guardian: First Name',
        validation: yup.string().trim().required('Please enter your first name'),
      },
      {
        name: 'lastName',
        type: 'text',
        placeholder: 'Parent/Guardian: Last Name',
        validation: yup.string().trim().required('Please enter your last name'),
      },
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        validation: yup
          .string()
          .email('Email is invalid')
          .required('Email is required')
          .matches(/^[^+]+$/, 'Email must not contain "+" signs'),
      },
      {
        name: 'phoneNumber',
        type: 'tel',
        placeholder: 'Phone Number',
        validation: yup
          .string()
          .required('Phone number is required')
          .matches(PHONE_REGEX, 'Must be a valid phone number'),
      },
      {
        name: 'childName',
        type: 'text',
        placeholder: "Child's Name",
        validation: yup.string().required("Child's name is required"),
      },
      {
        name: 'childAge',
        type: 'number',
        placeholder: "Child's Age",
        validation: yup
          .number()
          .typeError("Child's age is required")
          .required("Child's age is required")
          .positive('Please enter a valid age')
          .integer('Please enter a valid age'),
      },
    ];

    if (!validLocation) {
      fields.push({
        name: 'location',
        type: 'select',
        placeholder: 'Select Location',
        validation: yup.string().required('Please select a location'),
        options: locationsAllowed.map((location) => ({
          id: location,
          value: location,
          displayValue: location,
        })),
      });
    }

    return fields;
  }, [validLocation]);

  useLoadScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`);

  const onSubmitHandler = async (data: CommonRegistrationFormTypes) => {
    setIsLoading(true);
    if (!IS_TEST_ENVIRONMENT && process.env.REACT_APP_IS_CAPTCHA_DISABLED !== 'true') {
      try {
        const recaptchaToken = await executeRecaptchaV3(recaptchaSiteKey);
        const recaptchaResult = await verifyRecaptcha(recaptchaToken);
        if (!recaptchaResult.success || recaptchaResult.score < 0.3) {
          setErrorMessage('reCAPTCHA challenge failed');
          setIsLoading(false);
          return;
        }
      } catch (err) {
        setErrorMessage('reCAPTCHA challenge failed');
        setIsLoading(false);
        return;
      }
    }
    try {
      const {locations__drop_down_, ...otherUrlParams} = urlParams;

      const hubSpotData: Record<string, string> = {
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        phone: data.phoneNumber,
        locations__drop_down_: validLocation || data.location,
        name_of_child: data.childName,
        date_of_birth: data.childAge.toString(),
        ...otherUrlParams,
      };

      const formId = process.env.REACT_APP_HUBSPOT_FTC_DSR_CLASS_FORM_ID ?? '';
      const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID ?? '';
      await submitHubSpotForm(formId, portalId, hubSpotData);
      console.log('Form submitted successfully');
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Classes</title>
      </Helmet>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar showHamburger={false} />

        <div className="flex flex-col items-center my-4">
          <PageHeading text="TOCA Soccer Classes" />
        </div>
        <div className="flex flex-col items-center gap-8">
          {isSubmitted ? (
            <div className="max-w-[400px] text-base text-primary font-medium text-center">
              <h2 className="text-xl font-bold mb-4">Thank You!</h2>
              <p>
                Thank you for your interest in TOCA Classes! A representative from your center will be reaching out to
                you.
              </p>
            </div>
          ) : (
            <>
              <p className="max-w-[400px] text-base text-primary font-medium text-center">
                Schedule a FREE, no-commitment class for your child.
              </p>

              <div className="w-full max-w-[700px]">
                <div className="relative grow flex flex-col w-full py-8 px-8 bg-white border border-primary/20 rounded shadow-flat-lg mb-12">
                  {isLoading && (
                    <div className="absolute top-0 left-0 h-full w-full grid place-content-center bg-primary bg-opacity-80 rounded pointer-events-none">
                      <Spinner darkMode />
                    </div>
                  )}
                  {validLocation && (
                    <div className="w-full mb-8 text-left">
                      <div className="inline-block border-b border-black border-opacity-10">
                        <div className="text-black text-opacity-80 text-base font-medium py-2 px-2 border-t border-black border-opacity-10">
                          Your center: {validLocation}
                        </div>
                      </div>
                    </div>
                  )}

                  <CommonRegistrationForm
                    ctaButtonText="Submit"
                    onSubmit={onSubmitHandler}
                    errorMessage={errorMessage}
                    fields={formFields}
                    isLoading={isLoading}
                  />
                  <div className="flex justify-center my-[20px]">
                    <p className="text-[10px] text-[#aaa] text-center">
                      This site is protected by reCAPTCHA and the Google{' '}
                      <a
                        className="underline text-[#999]"
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        className="underline text-[#999]"
                        href="https://policies.google.com/terms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service
                      </a>{' '}
                      apply.
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

FtcDsrClassForm.displayName = 'FtcDsrClassForm';

export default FtcDsrClassForm;
