import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './BookingReview.module.scss';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../redux/reduxUtils';
import {
  clearDesiredDates,
  resetBookingPreferences,
  resetBookingSessions,
  setBookingSessions,
} from '../../../redux/slices/booking';
import {useCreateNewBookings} from '../../../api/Booking/useCreateNewBookings';
import {BookingInfo} from '../../../components/Booking/BookingInfo/BookingInfo';
import {SessionCard} from '../../../components/Booking/SessionCard/SessionCard';
import {SessionFetcher} from '../../../components/Booking/SessionCard/SessionFetcher';
import {DrawerModal} from '../../../components/DrawerModal/DrawerModal';
import {BookingFormButton} from '../../../components/Buttons/Form/FormButton';
import {AlertContainer, MessageContainer} from '../../../components/Booking/MessageContainer/MessageContainer';
import {StandardButton} from '../../../components/Buttons/Standard/StandardButton';
import {Checkbox} from '../../../components/Checkbox/Checkbox';
import {StandardSelect} from '../../../components/Select/StandardSelect/StandardSelect';
import {useProfiles} from '../../../api/User/useProfiles';
import {getNextWeekDay, getWeeklyDatesInclusive, parseDateTime, ParseDateTimeReturn} from '../../../utils/utils';
import {ProfileUpdate} from '../../../api/api';
import {Waiver} from '../Waiver/Waiver';
import {SavedBookingPreferences} from '../../../user/player-info.interface';
import {WEEK_REPEAT_OPTIONS} from '../../../constants/bookingConsts';
import {useInitializeBookings} from '../../../hooks/Booking/useInitializeBookings';
import {FormDivider} from '../../../components/Forms/FormDivider/FormDivider';
import {useUpdateProfile} from '../../../api/User/useUpdateProfile';
import {useQueryClient} from '@tanstack/react-query';
import {Helmet} from 'react-helmet';
import {useRestrictions} from '../../../hooks/useRestrictions';
import {useGetMboClientStatus} from '../../../hooks/useGetMboClientStatus';
import {PageHeading} from 'components/PageHeading/PageHeading';
import {AboveNavbarContainer} from 'components/Navbar/AboveNavbarContainer/AboveNavbarContainer';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {PageSubHeading} from 'components/PageSubHeading/PageSubHeading';
import {locationAllowsBookings} from '../../../feature-flags/booking-locations';
import {logAnalyticsEvent} from '../../../common/analytics-events';
import {generateVisitsQueryKey} from 'api/Booking/useGetMboVisits';
import {waiverRequired} from 'feature-flags/booking-waiver';

export const BookingReview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const {restrictions} = useRestrictions();
  const {createBookings} = useCreateNewBookings();
  const {currentProfile} = useProfiles();
  const [isEditingPrefs, setIsEditingPrefs] = useState(false);
  const [showWaiver, setShowWaiver] = useState(false);
  const [loading, setLoading] = useState(false);
  const {isActive} = useGetMboClientStatus();

  const {sessions, profileId, usingPreferences} = useAppSelector((state) => state.bookingReducer.bookingCreation);
  const {desiredDates, preferredSession} = useAppSelector(
    (state) => state.bookingReducer.bookingCreation.playerPreferences
  );
  const sessionErrors = sessions.find((s) => s.bookingStatus?.error || s.bookingStatus?.unavailableTime);

  const bookingPrefs = currentProfile.preferences?.booking;
  //parsedStartDate should only be used with a truthy 'bookingPrefs'!
  const parsedStartDate = parseDateTime(bookingPrefs?.referenceStartDate);

  useEffect(() => {
    if (!locationAllowsBookings(currentProfile?.mboDetails?.siteId)) {
      navigate('/home');
    }

    logAnalyticsEvent('booking_review', {
      profileId: currentProfile._id,
      mboSiteId: currentProfile.mboDetails?.siteId,
      mboClientId: currentProfile.mboDetails?.clientId,
      sessionCount: sessions?.length,
    });
  }, [currentProfile, navigate, sessions]);

  useEffect(() => {
    if (currentProfile.waiver?.signed && showWaiver) {
      setShowWaiver(false);
    }
  }, [currentProfile.waiver?.signed, showWaiver]);

  useEffect(() => {
    // if all desired dates have a corresponding session, clear the 'desiredDates' array
    // the 'sessions' array will then be used exclusively, during editing and error handling
    if (desiredDates && !desiredDates.filter((date) => !sessions.find((session) => session.dateStr === date)).length) {
      dispatch(clearDesiredDates());
    }
  }, [desiredDates, dispatch, sessions]);

  const openPreferencesDrawer = useCallback(() => {
    setIsEditingPrefs(true);
  }, []);

  const closePreferencesDrawer = useCallback(() => {
    setIsEditingPrefs(false);
  }, []);

  const confirmBookings = useCallback(
    async ({waiverValidated, override}: {waiverValidated?: boolean; override?: boolean}) => {
      if (restrictions.bookings.readOnly) {
        return;
      }

      if (currentProfile && sessions.length) {
        if (waiverRequired && !override && !currentProfile.waiver?.signed && !waiverValidated) {
          setShowWaiver(true);
          return;
        } else {
          if (showWaiver) {
            setShowWaiver(false);
          }

          setLoading(true);
          const appointmentResults = await createBookings({
            sessions,
            player: currentProfile,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            location: currentProfile.location!, //TODO this and all currentProfile.location assertions should be improved
          });

          if (appointmentResults) {
            dispatch(
              setBookingSessions(
                appointmentResults.map((s) => ({
                  ...s.session,
                  bookingStatus: {
                    confirmed: s.confirmed,
                    error: s.error,
                  },
                }))
              )
            );

            queryClient.invalidateQueries({
              queryKey: generateVisitsQueryKey({clientId: currentProfile.mboDetails?.clientId}),
              refetchType: 'all',
            });

            setLoading(false);
            navigate('/bookings/confirmation');
          }
        }
      } else {
        console.debug('no sessions, or no clientId found');
      }
    },
    [
      restrictions.bookings.readOnly,
      currentProfile,
      sessions,
      showWaiver,
      createBookings,
      dispatch,
      queryClient,
      navigate,
    ]
  );

  useEffect(() => {
    if (!(desiredDates || sessions.length)) {
      navigate('/bookings');
    } else if (currentProfile._id !== profileId) {
      navigate('/home');
    }
  }, [navigate, desiredDates, sessions, currentProfile, profileId]);

  if (!(desiredDates || sessions.length)) {
    return null;
  }

  if (waiverRequired && showWaiver) {
    return <Waiver onSubmit={confirmBookings} closeWaiver={() => setShowWaiver(false)} />;
  }

  if (loading) {
    return <LoadingAnimationFullHeight />;
  }

  return (
    <>
      <Helmet>
        <title>MyTOCA | Review & Book</title>
      </Helmet>
      <div className="lg:max-w-[900px] lg:w-full lg:mx-auto lg:px-8 lg:border-x border-solid border-grey-light">
        <PageHeading text="Book Sessions" />
        <BookingInfo />

        {sessionErrors && (
          <div style={{marginBottom: '12px'}}>
            <AlertContainer>
              Some sessions are unavailable at your preferred time. You may select an alternative time instead. Please
              review below.
            </AlertContainer>
          </div>
        )}

        {bookingPrefs && usingPreferences && (
          <div className={styles.prefsContainer}>
            <PageSubHeading text="Using Your Preferences:" />
            <MessageContainer>
              <div className={styles.flexColumns}>
                <div className={styles.info}>
                  <p>
                    <span>Day & Time: </span>
                    {parsedStartDate.dayStrLong},&nbsp;
                    {parsedStartDate.time}
                  </p>
                  <p>
                    <span>Repeat:</span> {bookingPrefs.weeks > 1 ? `Weekly for {bookingPrefs.weeks} weeks` : `None`}
                  </p>
                </div>
                <div className={styles.prefsEdit}>
                  <StandardButton onClick={openPreferencesDrawer} text="Edit" variant="light" />
                </div>
              </div>
            </MessageContainer>
          </div>
        )}

        {desiredDates ? (
          <div className={styles.container}>
            <PageSubHeading
              text={`You are booking ${desiredDates.length} session${desiredDates.length !== 1 ? 's' : ''}`}
            />

            <ul className={styles.sessionsList}>
              {desiredDates.map((date, i) => {
                const matchingSession = sessions.find((s) => s.dateStr === date);
                return matchingSession ? (
                  <li key={`session-card-wrapper-${i}`}>
                    <SessionCard session={matchingSession} displayOnly />
                  </li>
                ) : (
                  <li key={`session-card-wrapper-${i}`}>
                    <SessionFetcher date={date} referenceSession={preferredSession ?? sessions[0]} />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className={styles.container}>
            <PageSubHeading text={`You are booking ${sessions.length} session${sessions.length !== 1 ? 's' : ''}`} />

            <ul className={styles.sessionsList}>
              {sessions.map((session, i) => (
                <li key={`session-card-wrapper-${i}`}>
                  <SessionCard session={session} />
                </li>
              ))}
            </ul>
          </div>
        )}

        {isEditingPrefs && bookingPrefs ? (
          <DrawerModal asModal closeOnClick={closePreferencesDrawer}>
            <PreferencesModal
              bookingPrefs={bookingPrefs}
              parsedStartDate={parsedStartDate}
              closeModal={() => setIsEditingPrefs(false)}
            />
          </DrawerModal>
        ) : (
          <AboveNavbarContainer>
            {desiredDates?.length ? (
              <BookingFormButton hasError isPrimary text="Confirming Availability..." />
            ) : isActive(currentProfile.mboDetails?.clientId) ? (
              <BookingFormButton
                hasError={!!sessionErrors}
                isPrimary
                text={`book ${sessions.length} session${sessions.length > 1 ? 's' : ''} `}
                onClick={confirmBookings as React.MouseEventHandler<HTMLButtonElement>} //this is kindof hacky but the event handler won't trigger the validation prop
              />
            ) : (
              <BookingFormButton hasError isPrimary text="your account is inactive" onClick={() => navigate('/help')} />
            )}
          </AboveNavbarContainer>
        )}
      </div>
    </>
  );
};

export const PreferencesModal = memo(
  ({
    bookingPrefs,
    parsedStartDate,
    closeModal,
  }: {
    bookingPrefs: SavedBookingPreferences;
    parsedStartDate: ParseDateTimeReturn;
    closeModal: () => void;
  }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {currentProfile} = useProfiles();
    const {initializeBookings} = useInitializeBookings();
    const {updateProfile} = useUpdateProfile();

    const {day, weeks, startTime} = bookingPrefs;
    const [loading, setLoading] = useState(false);
    const [repeating, setRepeating] = useState(true);
    const [repeatWeeks, setRepeatWeeks] = useState(weeks.toString());

    const startDateOptions = useMemo(() => {
      return getWeeklyDatesInclusive({startDate: getNextWeekDay({day}), numWeeks: 6}).map((date) => {
        const {dayStrShort, monthStr, day} = parseDateTime(date);
        return {
          value: date,
          displayValue: `${dayStrShort}, ${monthStr} ${day}`,
        };
      });
    }, [day]);
    const [startDate, setStartDate] = useState(startDateOptions[0].value);

    const selectNewSession = useCallback(() => {
      dispatch(clearDesiredDates());
      dispatch(resetBookingSessions());
      dispatch(resetBookingPreferences());
      navigate(`/bookings`);
    }, [dispatch, navigate]);

    const onSubmit = useCallback(async () => {
      if (!currentProfile) {
        console.error('Cannot set preferences without current profile');
        return;
      }

      const newWeeks = repeating ? parseInt(repeatWeeks) : 0;
      const profileUpdate: ProfileUpdate = {
        profileId: currentProfile._id,
        update: {
          preferences: {
            booking: {
              referenceStartDate: `${startDate}T${startTime}`, // 2023-01-20T00:50:00
              weeks: newWeeks,
              //the below is all written out to remove 'referenceEndDate' which the update api doesn't like
              day: bookingPrefs.day,
              sessionType: bookingPrefs.sessionType,
              startTime: bookingPrefs.startTime,
              endTime: bookingPrefs.endTime,
              coach: bookingPrefs.coach,
            },
          },
        },
      };

      setLoading(true);
      await updateProfile(profileUpdate);
      initializeBookings({startDate, weeks: newWeeks});
      closeModal();
    }, [
      bookingPrefs.coach,
      bookingPrefs.day,
      bookingPrefs.endTime,
      bookingPrefs.sessionType,
      bookingPrefs.startTime,
      closeModal,
      currentProfile,
      initializeBookings,
      repeatWeeks,
      repeating,
      startDate,
      startTime,
      updateProfile,
    ]);

    return (
      <div className={styles.preferencesModalContainer}>
        <h2>edit preferences</h2>

        <div className={styles.preferencesPane}>
          <div className={styles.selectButton}>
            <StandardButton text="Select different time" variant="primary" onClick={selectNewSession} />
          </div>
          <FormDivider text="or" />
          <p className={styles.dayTimeBold}>Day & Time</p>
          <div className={styles.dayTimeRow}>
            <p>
              {parsedStartDate.dayStrLong},&nbsp;
              {parsedStartDate.time}&nbsp;starting
            </p>
            <StandardSelect
              id="starting-select"
              options={startDateOptions}
              onChange={(e) => setStartDate(e.currentTarget.value.toString())}
              defaultValue={parsedStartDate.dateStr}
            />
          </div>
        </div>

        <div className={styles.repeatSelection}>
          <Checkbox id="repeatcheck" labelText="" defaultChecked onChange={() => setRepeating((prev) => !prev)} />
          <p className={styles.repeatText}>Repeat weekly for </p>
          <StandardSelect
            id="week-select"
            options={WEEK_REPEAT_OPTIONS}
            onChange={(e) => setRepeatWeeks(e.currentTarget.value.toString())}
            defaultValue={weeks}
          />
        </div>
        <p className={styles.smallText}>We recommend at least 12 training sessions for best results.</p>

        <BookingFormButton type="submit" isPrimary onClick={onSubmit} hasError={loading}>
          save preferences
        </BookingFormButton>
      </div>
    );
  }
);
PreferencesModal.displayName = 'PreferencesModal';
