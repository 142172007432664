import {useEffect} from 'react';
import {getLocationBySiteId} from '../../constants/locations';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {setFkoAppointmentDates, setFkoSiteId} from 'redux/slices/fkoFormData';
import {logAnalyticsEvent} from '../../common/analytics-events';
import {Helmet} from 'react-helmet';
import {FKOFormContainer} from '../../components/FKO/FKOFormContainer/FKOFormContainer';
import {FormHeading} from '../../components/Forms/FormHeading/FormHeading';
import {CreatePlayerForm} from './CreatePlayerForm';
import {FkoPlayersList} from './FkoPlayersList';
import {FkoSubmitButtonAndLogic} from './FkoSubmitButtonAndLogic';
import {FkoBackupLocationForm} from './FkoBackupLocationForm';
import {getLocationSpecificFkoBookingDates} from 'common/fko-utils';
import {FetchAppointmentsHack} from 'pages/RegistrationLanding/FetchAppointmentsHack';
import {useGetSport} from 'common/useGetSport';

export const PlayersRoot = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {sessionTypeName, isDbat, isPlayerAssessment, basePath, fkoAnalyticsPrefix} = useGetSport();

  const {appointmentCalendar, appointmentDates, initialParams, playersArray, siteId, userData} = useAppSelector(
    (state) => state.fkoFormDataReducer
  );

  const locationName = siteId ? getLocationBySiteId(siteId)?.name : undefined;
  const apptCalExists = !(Object.keys(appointmentCalendar).length === 0);

  useEffect(() => {
    if (!apptCalExists) {
      const fkoDateInfo = getLocationSpecificFkoBookingDates({
        siteId: siteId,
        dateOverride: initialParams.date,
        dateOverrideEnd: initialParams.dateEnd,
        isPlayerAssessment,
      });
      if (fkoDateInfo) {
        dispatch(
          setFkoAppointmentDates({
            daysAhead: fkoDateInfo.bookingWindowInDays,
            startDate: fkoDateInfo.startDateStr,
            endDate: fkoDateInfo.endDateStr,
          })
        );
      }

      if (!fkoDateInfo && siteId) {
        console.error('error getting fkoDateInfo for siteId: ', siteId);
      }
    }
  }, [apptCalExists, dispatch, initialParams.date, initialParams.dateEnd, siteId]);

  useEffect(() => {
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_players_page_view`);
    if (initialParams.embed === 'true' && window.parent) {
      window.parent.postMessage('fkoShrinkIframe', '*');
    }
  }, [fkoAnalyticsPrefix, initialParams.embed]);

  useEffect(() => {
    if (!userData) {
      navigate(basePath, {replace: true});
    }
  }, [basePath, navigate, userData]);

  if (!userData) {
    return null;
  }

  const pageTitle = `MyTOCA | Schedule ${sessionTypeName}`;
  const formHeading = `Schedule ${sessionTypeName}`;
  let mboAppointmentNotes: string | undefined = 'Scheduled online.';

  if (isDbat) {
    mboAppointmentNotes = 'Promotional Lesson: online booking'; // NOTE: HubSpot logic looks for this specific string
  } else if (isPlayerAssessment) {
    mboAppointmentNotes = `Player Assessment: online booking, teamId: ${initialParams.teamId}`;
  }

  // if players -> show player select/edit screen
  // if no players -> show add player screen
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <FKOFormContainer>
        {playersArray.length ? (
          <>
            <FormHeading heading={formHeading} />

            {siteId ? (
              <>
                <div className="mt-[-24px] mb-3">
                  <div className="text-[13px] text-center text-grey-xdark text-opacity-80">
                    Your Center: {locationName}
                  </div>
                </div>
                {apptCalExists && <FkoPlayersList />}
                <FkoSubmitButtonAndLogic mboAppointmentNotes={mboAppointmentNotes} />
              </>
            ) : (
              <FkoBackupLocationForm
                onSubmitHandler={(data) => {
                  dispatch(setFkoSiteId(data.location));
                }}
              />
            )}
          </>
        ) : (
          <>
            <FormHeading
              heading="Who's Playing?"
              subheading={
                <span>
                  Add your first player.
                  <br />
                  You can add more players later.
                </span>
              }
            />
            <CreatePlayerForm showAccountHolderCheckbox={true} siteId={siteId} />
          </>
        )}
      </FKOFormContainer>

      {siteId && appointmentDates ? (
        <FetchAppointmentsHack
          siteId={siteId}
          startDate={`${appointmentDates.startDate}`}
          endDate={`${appointmentDates.endDate}`}
          sessionTypeNames={initialParams?.sessionTypeNames}
        />
      ) : null}
    </>
  );
};
