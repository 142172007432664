import './index.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import {createRoot} from 'react-dom/client';
import {AxiosInterceptor} from './api/axios.config';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
AxiosInterceptor();

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error('App root element not found');
}

reportWebVitals();
